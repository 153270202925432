.xword{
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.xword.a1{
    background: #EF476F;
    color: white;
}

.xword.a2{
    background: #FFD166;
    color: white;
}

.xword.b1{
    background: #06D6A0;
    color: white;
}

.xword.b2{
    background: #118AB2;
    color: white;
}

.xword.c1{
    background: #073B4C;
    color: white;
}

.xword.c2{
    background: #0CB0A9;
    color: white;
}


/*'#EF476F',*/
/*'#FFD166',*/
/*'#06D6A0',*/
/*'#118AB2',*/
/*'#073B4C',*/
/*'#0CB0A9',*/
/*'#1E4D5C',*/
/*'#325D6B',*/
/*'#1094B0',*/
/*'#83D483',*/
/*'#E8BE5D'*/