.rnd_handle{
    right: 10px;
    left: 10px;
    top: 5px;
    padding: 3px;
    position: absolute;
    cursor: grabbing;
    border-radius: 4px;
    background: whitesmoke;
    opacity: 0.6;
}

body > iframe{
    display: none !important;
}

.rnd_handle.bottom{
    bottom: 3px !important;
    top: unset !important;
    padding: 2px;
}

.no_grab {
    user-drag: none !important;
    -webkit-user-drag: none !important;;
    user-select: none !important;;
    -moz-user-select: none !important;;
    -webkit-user-select: none !important;;
    -ms-user-select: none !important;;
}