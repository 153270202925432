.Difference {
    font-family: monospace;
}

.Difference > .del {
    background-color: rgb(255, 224, 224);
    text-decoration: none;
}

.Difference > .ins {
    background-color: rgb(201, 238, 211);
    text-decoration: none;
}
