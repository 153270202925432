.text_placeholder iframe{
    border: none !important;
    width: 100% !important;
    height: 420px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.2;
    }
}

.text_placeholder{
    font-family: sans-serif;
    font-size: 16px;
    color: black;
}